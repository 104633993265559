/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TranslatePipe } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { FlexComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    TranslatePipe,
    FlexComponent,
  ],
})
export class FooterComponent {
  fossUrl = environment.fossUrl;

  getCurrentYear(): string {
    return new Date().getFullYear().toString();
  }
}
