<!--
~ This code is protected by intellectual property rights.
~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
-->
<!-- button to skip to checked in screen, change showTestButton to true -->
@if (showTestButton) {
  <ng-container style="position: absolute">
    <p-button-group>
      <p-button data-testid="testMethodOn" [variant]="'secondary'" (click)="testingShowSuccess()">
        Show Success
      </p-button>
      <p-button data-testid="testMethodError" [variant]="'secondary'" (click)="scanErrorHandler()">
        Show Error
      </p-button>
      <p-button data-testid="testMethodOff" [variant]="'secondary'" (click)="newCheckin()">
        Reset
      </p-button>
    </p-button-group>
  </ng-container>
}
<!-- -->
<div class="scanner">
  @if (!showError && showCheckIn) {
    <div>
      <p-heading [align]="'center'">{{ 'pageTitle' | translate }}</p-heading>
      <p-text [align]="'center'">{{ 'checkInHint' | translate }}</p-text>
    </div>
  }
  <div [style.visibility]="showCheckIn ? 'visible' : 'hidden'">
    <p-select-wrapper class="scanner__select-camera">
      <select (change)="onSelectDeviceEvent($event)">
        @for (c of scanner.devices.value; track c; let i = $index) {
          <option [value]="c.deviceId" [selected]="i === scanner.deviceIndexActive"
            data-testid="c.deviceId">
            {{c.label}}
          </option>
        }
      </select>
    </p-select-wrapper>
    <ngx-scanner-qrcode class="scanner__qr-scanner"
      #scanner
      [config]="config"
      [isBeep]="false"
      (event)="onScanEvent($event)"
    (error)="scanErrorHandler()"></ngx-scanner-qrcode>
  </div>
  @if (showSendingDialog) {
    <div class="scanner-loading" data-testid="sendingDialog" >
      <p-text [size]="'medium'" [weight]="'regular'" [align]="'center'">{{ 'sendingStatus' | translate }}</p-text>
      <p-text [align]="'center'" [color]="'contrast-low'">{{ 'sendingHint' | translate }}</p-text>
      <p-spinner [size]="'small'" aria-label="Loading"></p-spinner>
    </div>
  }
  @if (showSuccess) {
    <div class="scanner-success" data-testid="successDialog">
      <p-icon [size]="'large'" [name]="'success'" [color]="'notification-success'"></p-icon>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <p-text [size]="'medium'" [weight]="'regular'" [align]="'center'" [color]="'notification-success'">{{ 'successStatus' | translate }}</p-text>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <p-text [align]="'center'">{{ 'successHint' | translate }}</p-text>
      <mycontent-spacer [size]="'small'"></mycontent-spacer>
      <div class="scanner-success__box">
        <p-text>
          <strong>{{ 'ticketAttendeeName' | translate }}: </strong>
          <span data-testid="attendeeName">{{ checkInResponse().attendeeFullName }}</span>
        </p-text>
        <br>
          <p-text>
            <strong>{{ 'ticketGuests' | translate }}: </strong>
            <span data-testid="numberOfGuests">{{ checkInResponse().ticket.guestNumber + 1 }}/{{ checkInResponse().totalNumberOfGuests }}</span>
          </p-text>
          <br>
            <p-text>
              <strong>{{ 'timeslotDetails' | translate }}: </strong>
              <span data-testid="startDate">{{ getFormattedStartDate(checkInResponse().timeslot.startDate) }} - </span>
              <span data-testid="endDate">{{ getFormattedEndDate(checkInResponse().timeslot.startDate, checkInResponse().timeslot.endDate) }}</span>
            </p-text>
            <br>
              <p-text>
                <strong>{{ 'ticketEvent' | translate }}: </strong>
                <span data-testid="eventName">{{ checkInResponse().eventName }}</span>
              </p-text>
            </div>
            <div class="scanner-success__button">
              <p-button data-testid="newCheckin" [variant]="'primary'" (click)="newCheckin()">{{ 'buttonNewCheckIn' | translate }}</p-button>
            </div>
          </div>
        }
        @if (showError) {
          <div class="scanner-error" data-testid="errorDialog">
            <div >
              <p-icon [size]="'large'" [source]="'../../../assets/error-outline.svg'" [color]="'notification-error'"></p-icon>
              <p-text [size]="'medium'" [align]="'center'" [weight]="'semi-bold'" [color]="'notification-error'">{{ 'errorStatus' | translate }}</p-text>
              <p-text [align]="'center'">
                <span data-testid="errorText">{{ errorMessage() }}</span>
              </p-text>
            </div>
            <div class="scanner-error__button">
              <p-button data-testid="scanTicket" [variant]="'primary'" (click)="newCheckin()">{{ 'buttonScanTicket' | translate }}</p-button>
            </div>
          </div>
        }
      </div>
