/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { FactoryProvider, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const initTranslateServiceProviderFactory =
  (translateService: TranslateService) => () => {
    translateService.setDefaultLang(`en_GB`);
  };

export const initTranslateServiceProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initTranslateServiceProviderFactory,
  deps: [TranslateService],
  multi: true,
};
