/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AfterViewInit, Component, inject, OnInit, signal, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CheckinModel } from '../../models/checkin.model';
import { NgxScannerQrcodeComponent, NgxScannerQrcodeModule, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { checkinResponse } from '../../responses/checkin.response';
import { CheckinService } from '../../services/checkin.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Country } from '../../resources/country-locale-mapping';
import { delay } from 'rxjs';
import { FooterComponent } from '../footer/footer.component';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { isSameDay } from 'date-fns';
import { DateFormatterService } from '@ui/shared/util';
import { SpacerComponent } from '@ui/shared/ui';

@Component({
  selector: 'mycontent-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    PorscheDesignSystemModule,
    NgxScannerQrcodeModule,
    FooterComponent,
    TranslatePipe,
    SpacerComponent
  ],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('scanner') scanner: NgxScannerQrcodeComponent;

  http = inject(HttpClient);
  route = inject(ActivatedRoute);
  checkinService = inject(CheckinService);
  translateService = inject(TranslateService);
  dateFormatterService = inject(DateFormatterService);

  // set to true to enable button to skip to checked in screen with test data and see layout
  showTestButton = false;
  country = '';
  showCheckIn = true;
  showSendingDialog = false;
  showSuccess = false;
  showError = false;
  errorMessage = signal<string>('');

  checkInResponse = signal<CheckinModel>({
    attendeeFullName: '',
    lastname: '',
    firstname: '',
    salutation: '',
    title: '',
    eventName: '',
    totalNumberOfGuests: 0,
    ticket: {},
    timeslot: {},
  });

  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: {ideal: 1280},
        height: {ideal: 720},
      },
    },
  };

  selectedDeviceId: string = null;

  ngOnInit(): void {
    this.country = this.route.snapshot.paramMap.get('country');
    this.getTranslation(this.country);
  }

  ngAfterViewInit(): void {
    this.scanner.isReady.pipe(delay(300)).subscribe(() => {
      this.handle(this.scanner, 'start');
    });
  }

  public onScanEvent(event: ScannerQRCodeResult[]): void {
    if (event[0]?.value) {
      this.scanSuccessHandler(event[0].value);
    }
  }

  public onSelectDeviceEvent(event: Event): void {
    this.selectedDeviceId = (event.target as HTMLSelectElement).value;
    this.scanner.playDevice(this.selectedDeviceId);
  }

  public handle(action: NgxScannerQrcodeComponent, fn: string): void {
    const playSelectedDeviceDefaultToFacingBack = devices => {
      if (this.selectedDeviceId && devices.find(f => f.deviceId === this.selectedDeviceId)) {
        action.playDevice(this.selectedDeviceId);
      }
      // / Default Back Facing Camera
      const device = devices.find(f => (/back|rear|environment/gi.test(f.label)));
      this.selectedDeviceId = device ? device.deviceId : devices[0].deviceId;
      action.playDevice(this.selectedDeviceId);
    };

    if (fn === 'start') {
      action[fn](playSelectedDeviceDefaultToFacingBack).subscribe(r => console.log(fn, r), alert);
    } else {
      action[fn]().subscribe(console.log, console.error);
    }
  }

  newCheckin(): void {
    this.showSuccess = false;
    this.showError = false;
    this.showCheckIn = true;
    this.scanner.play();
  }

  camerasNotFoundHandler(): void {
    this.showCheckIn = false;
    this.errorMessage.set(this.translateService.instant('errorMessageNoCamPermissions'));
    this.showError = true;
  }

  scanSuccessHandler(scannedText: string): void {
    this.scanner.pause();
    this.showCheckIn = false;
    this.showSendingDialog = true;

    this.checkinService.checkInTicket(scannedText)
      .subscribe({
        next: (response: HttpResponse<CheckinModel>) => {
          this.showSendingDialog = false;
          this.showSuccess = response.status === 200;

          if (response.body) {
            this.checkInResponse.set(response.body);
          }
        },
        error: error => {
          this.showSendingDialog = false;
          this.errorMessage.set(this.translateService.instant('errorMessageGeneralError'));

          if (error.status === 400 || error.status === 404) {
            let date = '';
            if (error.error.details) {
              date = this.convertDatetime(error.error.details);
            }
            switch (error.error.code) {
              case 'TICKET_ALREADY_CHECKED_IN':
                error.error.message = this.translateService.instant('errorMessageTicketAlreadyCheckedIn');
                break;
              case 'REGISTRATION_CANCELLED':
                error.error.message = this.translateService.instant('errorMessageRegistrationCancelled');
                break;
              case 'INVALID_CHECK_IN_TIME':
                error.error.message = this.translateService.instant('errorMessageInvalidCheckInTime');
                break;
              case 'TICKET_NOT_FOUND':
                error.error.message = this.translateService.instant('errorMessageTicketNotFound');
                break;
              case 'EVENT_NOT_FOUND':
                error.error.message = this.translateService.instant('errorMessageEventNotFound');
                break;
              default:
                break;
            }
            this.errorMessage.set(`${error.error.message} ${date}`);
          }
          this.showError = true;
        },
      });
  }

  scanErrorHandler(): void {
    this.showSendingDialog = false;
    this.errorMessage.set(this.translateService.instant('errorMessageGeneralError'));
    this.scanner.pause();
    this.showCheckIn = false;
    this.showError = true;
  }

  getFormattedStartDate(dateString: string): string {
    return this.dateFormatterService.formatDateWithTime(dateString);
  }

  getFormattedEndDate(startDateString: string, endDateString: string): string {
    if (isSameDay(new Date(startDateString), new Date(endDateString))) {
      return this.dateFormatterService.formatTime(endDateString);
    }
    return this.dateFormatterService.formatDateWithTime(endDateString);
  }

  convertDatetime(dateString: string): string {
    return this.dateFormatterService.formatDateWithTime(dateString);
  }

  getTranslation(country = 'gb'): void {
    const locale = Country.find(country);
    this.translateService.getTranslation(locale);
    this.translateService.setDefaultLang(locale);
  }

  public testingShowSuccess(): void {
    this.scanner.pause();
    this.showCheckIn = false;
    this.showSuccess = true;

    this.checkInResponse.set(checkinResponse);
  }
}
