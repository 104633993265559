/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CheckinModel } from '../models/checkin.model';

@Injectable({
  providedIn: 'root',
})
export class CheckinService {
  private http: HttpClient = inject(HttpClient);

  checkInTicket(scannedText: string): Observable<HttpResponse<CheckinModel>> {
    const url = `${environment.api.url}/tickets/${scannedText}/action:check-in`;
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.put<CheckinModel>(url, JSON.stringify('{}'), {headers, observe: 'response'});
  }
}
