/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export class Country {
  public static readonly countryLocaleMap = {
    de: 'de_DE',
    es: 'es_ES',
    fr: 'fr_FR',
    gb: 'en_GB',
    pt: 'pt_PT',
    sg: 'en_GB',
    en: 'en_GB',
    us: 'en_US',
    jp: 'ja_JP',
    ch: 'de_DE',
    it: 'it_IT',
    be: 'fr_FR',
    my: 'en_GB',
    tw: 'zh_TW',
    nz: 'en_GB',
    au: 'en_GB',
    no: 'no_NO',
    ca: 'en_GB',
    nl: 'nl_NL',
    mx: 'es_ES',
    pr: 'es_ES',
  };

  public static find(countryCode: string): string | null {
    // The matching should be tolerant towards capitalization of the country code
    return Country.countryLocaleMap[(countryCode).toLowerCase()] || null;
  }
}
