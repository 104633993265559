/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { ScannerTranslateLoaderService } from './app/services/scanner-translate-loader.service';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { AppRoutingModule } from './app/app-routing.module';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { initTranslateServiceProvider } from './app/providers/init-translate-service.provider';
import { DatePipe } from '@angular/common';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      PorscheDesignSystemModule,
      NgxScannerQrcodeModule
    ),
    DatePipe,
    initTranslateServiceProvider,
    provideHttpClient(withInterceptorsFromDi()),
    provideTranslateService({
      loader: { provide: TranslateLoader, useClass: ScannerTranslateLoaderService }
    })
  ]
}).catch(err => console.error(err));
