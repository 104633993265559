<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<div class="footer">
    <mycontent-flex [justifyContent]="'center'">
        <p-text [size]="'small'" [theme]="'dark'" [align]="'center'">
          ©{{ getCurrentYear() }} Porsche Sales &amp; Marketplace GmbH
          <a class="footer__link" [href]="'https://ppn.porsche.com/footer/privacy.html'" target="_blank">{{ 'footer.privatePolicy' | translate }}.</a> &nbsp;
          <a class="footer__link" [href]="fossUrl" target="_blank">{{ 'footer.licenseAgreement' | translate }}.</a>
        </p-text>
    </mycontent-flex>
</div>
