/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { CheckinModel, TicketModel, TimeslotModel } from '../models/checkin.model';

export const timeslotResponse: TimeslotModel = {
  startDate: '2023-05-31T12:24:44Z',
  endDate: '2023-06-02T12:24:44Z',
};

export const ticketResponse: TicketModel = {
  guestNumber: 0,
  checkInDateTime: '2023-06-02T12:24:44Z',
};

export const checkinResponse: CheckinModel = {
  attendeeFullName: 'Jannik Ribeiro',
  lastname: 'Ribeiro',
  firstname: 'Jannik',
  salutation: 'Mr',
  title: 'Dr.',
  eventName: 'Test Event Name',
  totalNumberOfGuests: 1,
  timeslot: timeslotResponse,
  ticket: ticketResponse,
};
