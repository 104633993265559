<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<mycontent-flex [justifyContent]="'center'">
  <div class="scanner-header">
    <p-wordmark></p-wordmark>
  </div>
</mycontent-flex>
